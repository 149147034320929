import { createClient, Entry } from 'contentful';

import { ContentfulQuery } from 'lib/contentful/model';
import { CONTENT_TYPE, LOCALE_CODE } from 'lib/types/generated/contentful';
import { NextLocale } from 'shared/constants';

const DEFAULT_ENVIRONMENT = 'master';

const spaceId = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;

if (!spaceId) {
  throw new Error('ENV entry not found: contentful space id');
}

if (!accessToken) {
  throw new Error('ENV entry not found: contentful access token ');
}

const client = createClient({
  space: spaceId,
  accessToken,
  environment: process.env.CONTENTFUL_ENVIRONMENT || DEFAULT_ENVIRONMENT,
});

export const CONTENT_TYPES: Partial<Record<string, CONTENT_TYPE>> = {
  hubArticle: 'blogArticle',
  hubCategory: 'postCategory',
  searchLandingPage: 'searchLandingPage',
  seaPage: 'seaPage',
  dynamicLandingPage: 'newLpMarketingWebsite',
  unsignedCaterers: 'unsignedCaterers',
  countryLandingPage: 'countryLandingPage',
  partnerLandingPage: 'partnerLandingPage',
  hubHomepage: 'hubHomepage',
  customerLandingPage: 'customerLandingPage',
  homePage: 'homePage',
  dynamicPage: 'dynamicPage',
};

export const PAGE_LIMIT = 20;
export const UPPER_LIMIT = 1000;

export async function getAllEntries<T extends Entry<T['fields']>>(query = {}) {
  return client.getEntries<T['fields']>({
    ...query,
    limit: UPPER_LIMIT,
  });
}

export async function getEntry<T extends Entry<T['fields']>>(
  query: ContentfulQuery<T> = {}
) {
  if (query.locale) {
    query.locale = toContentfulLocale(query.locale);
  }
  const { items, ...restData } = await client.getEntries<T['fields']>({
    ...query,
    limit: 1,
  });

  return {
    item: items.length ? items[0] : undefined,
    items,
    ...restData,
  };
}

export async function getEntryById<T extends Entry<T['fields']>>(
  id: string,
  query: ContentfulQuery<T> = {}
) {
  if (query.locale) {
    query.locale = toContentfulLocale(query.locale);
  }

  const item = await client.getEntry<T['fields']>(id, query);
  return item;
}

export async function getEntries<T extends Entry<T['fields']>>(
  query: ContentfulQuery<T> = {}
) {
  if (query.locale) {
    query.locale = toContentfulLocale(query.locale);
  }
  return client.getEntries<T['fields']>(query);
}
export function toContentfulLocale(nextLocale?: string): LOCALE_CODE {
  return nextLocale && nextLocale in localeMapping
    ? localeMapping[nextLocale as NextLocale]
    : 'de-DE';
}

export const localeMapping: Record<NextLocale, LOCALE_CODE> = {
  de: 'de-DE',
  en: 'en',
  da: 'en',
  'da-dk': 'en',
  'en-uk': 'en',
  'en-de': 'en',
  'de-at': 'de-DE',
  'de-ch': 'de-DE',
  'de-de': 'de-DE',
};
