import { Entry } from 'contentful';

import { IBlogArticleFields } from 'lib/types/generated/contentful';

export function articleCardShape({
  fields: {
    title,
    description,
    heroImage,
    postCategory,
    hubslug,
    slug,
    tags = [],
  },
  sys: { createdAt, updatedAt },
}: Entry<IBlogArticleFields>) {
  return {
    title,
    description,
    heroImage,
    hubslug,
    slug,
    tags,
    createdAt,
    updatedAt,
    postCategory: {
      id: postCategory.sys.id,
      ...postCategory.fields,
    },
  };
}

export function articleDetailShape({
  fields: {
    title,
    postCategory,
    slug,
    heroImage,
    body,
    metaTitle = '',
    metaDescription = '',
    robots,
    tags = [],
    buttonCta = '',
    buttonCtaCopy = '',
    buttonCtaSubtitle = '',
    buttonUrl = '',
    suggestedArticles = [],
    targetAudience = 'Customer',
    hasEnglishVersion,
    author,
    contactForm,
  },
  sys: { createdAt, updatedAt },
}: Entry<IBlogArticleFields>) {
  return {
    title,
    slug,
    author: author?.fields?.avatar
      ? { ...author.fields, avatar: { ...author.fields.avatar.fields } }
      : null,
    heroImage,
    body,
    metaTitle,
    metaDescription,
    robots,
    tags,
    buttonCta,
    buttonCtaCopy,
    buttonCtaSubtitle,
    buttonUrl,
    targetAudience,
    hasEnglishVersion: !!hasEnglishVersion,
    postCategory: {
      ...postCategory.fields,
    },
    suggestedArticles: [
      ...(suggestedArticles.filter((article) => !!article.fields) || []).map(
        articleCardShape
      ),
    ],
    createdAt,
    updatedAt,
    contactForm: contactForm || null,
  };
}

export type ParsedArticle = ReturnType<typeof articleDetailShape>;
