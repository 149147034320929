import { Entry } from 'contentful';

import {
  CONTENT_TYPES,
  getAllEntries,
  getEntries,
  getEntry,
  localeMapping,
  PAGE_LIMIT,
  toContentfulLocale,
} from 'lib/contentful/client';
import { ContentfulQuery } from 'lib/contentful/model';
import { articleCardShape, articleDetailShape } from 'lib/contentful/shapes';
import {
  IBlogArticle,
  IHubHomepage,
  IPostCategory,
  IPostCategoryFields,
  LOCALE_CODE,
} from 'lib/types/generated/contentful';

const SUGGESTED_ARTICLES_LIMIT = 3;
const DEFAULT_LOCALE = localeMapping.de;

const fetchPostCategories = async (locale = DEFAULT_LOCALE) => {
  const { items } = await getEntries<IPostCategory>({
    content_type: CONTENT_TYPES.hubCategory,
    limit: 20,
    locale,
  });

  return items;
};

export async function fetchAllArticles() {
  const { items } = await getAllEntries<IBlogArticle>({
    content_type: CONTENT_TYPES.hubArticle,
  });

  return items;
}

export async function fetchArticles(query?: ContentfulQuery<IBlogArticle>) {
  const { items } = await getEntries<IBlogArticle>({
    content_type: CONTENT_TYPES.hubArticle,
    order: '-sys.updatedAt',
    ...query,
    locale: toContentfulLocale(query?.locale),
  });

  return items;
}

export async function fetchRecentArticles(locale = DEFAULT_LOCALE) {
  const items = await fetchArticles({
    limit: 20,
    locale,
    order: '-sys.createdAt',
  });
  return items;
}

export async function fetchHubHomepage(locale = DEFAULT_LOCALE) {
  const { item } = await getEntry<IHubHomepage>({
    content_type: CONTENT_TYPES.hubHomepage,
    locale,
    order: '-sys.updatedAt',
  });
  return item?.fields;
}

export async function fetchFeaturedArticle() {
  const hubHomepage = await fetchHubHomepage();

  if (!hubHomepage?.featuredPost) {
    return undefined;
  }

  return articleCardShape(hubHomepage.featuredPost);
}

export async function fetchLatestArticles(locale: LOCALE_CODE) {
  const items = await fetchArticles({
    limit: 6,
    locale,
    order: '-sys.updatedAt',
  });

  return items.map(articleCardShape);
}

interface FetchArticleBySlugOptions {
  slug: string;
  locale?: LOCALE_CODE;
  suggestedArticlesLimit?: number;
}

export async function fetchArticleBySlug({
  slug,
  locale = DEFAULT_LOCALE,
  suggestedArticlesLimit = SUGGESTED_ARTICLES_LIMIT,
}: FetchArticleBySlugOptions) {
  const fetchArticlePromise = getEntry<IBlogArticle>({
    content_type: CONTENT_TYPES.hubArticle,
    'fields.slug': slug,
    locale,
    include: 5,
  });

  const [{ item }, categories] = await Promise.all([
    fetchArticlePromise,
    fetchPostCategories(locale),
  ]);

  if (!item || !item?.fields) {
    return;
  }

  const article = articleDetailShape(item);

  if (article.suggestedArticles.length < suggestedArticlesLimit) {
    const { items } = await fetchArticlesByCategory({
      category: article.postCategory.slug,
      pageLimit: suggestedArticlesLimit - article.suggestedArticles.length,
    });

    // NOTE: filter out the current article.
    const recentArticles = items.filter(
      (recentArticle) => recentArticle.fields.slug !== slug
    );

    article.suggestedArticles = [
      ...article.suggestedArticles,
      ...recentArticles.map(articleCardShape),
    ].map((_article) => mapPostCategoryToArticle(_article, categories));
  }

  return article;
}

const mapPostCategoryToArticle = (
  article: ReturnType<typeof articleCardShape>,
  categories: Entry<IPostCategoryFields>[]
): ReturnType<typeof articleCardShape> => {
  const categoryId = article.postCategory.id;
  const category = categories.find(
    (category) => category.sys.id === categoryId
  );
  return category
    ? { ...article, postCategory: { ...category.fields, id: category.sys.id } }
    : article;
};

interface fetchArticlesByCategoryOptions {
  category: string;
  page?: number;
  pageLimit?: number;
  locale?: LOCALE_CODE;
}

export async function fetchArticlesByCategory({
  category,
  page = 0,
  pageLimit = PAGE_LIMIT,
  locale = DEFAULT_LOCALE,
}: fetchArticlesByCategoryOptions) {
  const { items, total, skip, limit } = await getEntries<IBlogArticle>({
    content_type: CONTENT_TYPES.hubArticle,
    'fields.postCategory.fields.slug': category,
    'fields.postCategory.sys.contentType.sys.id': 'postCategory',
    limit: pageLimit,
    skip: pageLimit * page,
    locale,
    order: '-sys.updatedAt',
  });

  return {
    items,
    total,
    skip,
    limit,
  };
}

interface FetchArticleCardsByCategoryOptions {
  category: string;
  page?: number;
  pageLimit?: number;
  locale?: LOCALE_CODE;
}

export async function fetchArticleCardsByCategory({
  category,
  page = 0,
  pageLimit = PAGE_LIMIT,
  locale,
}: FetchArticleCardsByCategoryOptions) {
  const { items, total, skip, limit } = await fetchArticlesByCategory({
    category,
    page,
    pageLimit,
    locale,
  });

  return {
    items: items.map(articleCardShape),
    total,
    skip,
    limit,
  };
}

export async function fetchArticleCategories(locale: LOCALE_CODE) {
  const hubHomepage = await fetchHubHomepage(locale);
  if (!hubHomepage?.categories) {
    return [];
  }
  return hubHomepage.categories.map((item) => item.fields);
}
